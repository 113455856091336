import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout";

import { responsiveTitle1 } from "../components/typography.module.css";

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => {
  return (
    browser && (
      <Layout>
        <SEO title="404: Not found" />
        <h1 className={responsiveTitle1}>Page not found</h1>
        <p>Nothing was found at this location.</p>
      </Layout>
    )
  );
};

export default NotFoundPage;
